import React, { Fragment, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { MainHomeSlider } from '../../../Data/SliderSettingsData';
import { APIImage } from '../../Constant';
import Image from 'next/image';

const VegetablePoster = ({ nav2, slider1, slider }) => {
    const [defImg, setDefImg] = useState("data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=");
    const [activeSlide, setActiveSlide] = useState(0); // Track the current active slide

    // Ensure the default image is reset and slider initializes at the first slide
    useEffect(() => {
        setDefImg("");
        if (slider1.current) {
            slider1.current.slickGoTo(0);
        }
    }, [slider1]);

    // Function to handle banner click with the correct link
    const handleBannerClick = () => {
        const slide = slider[activeSlide];
        if (slide && slide.link) {
            window.open(slide.link, '_blank');
        }
    };

    return (
        <div className="poster-image slider-for custome-arrow classic-arrow-1" style={{ height: '100%' }}>
            <Slider
                style={{ height: '100%' }}
                asNavFor={nav2}
                {...MainHomeSlider}
                ref={(slider) => (slider1.current = slider)}
                beforeChange={(oldIndex, newIndex) => setActiveSlide(newIndex)} // Track slide changes
            >
                {slider?.map((el, i) => (
                    <div  onClick={handleBannerClick} key={i} className="h-100">
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                zIndex: 100,
                                cursor: el.link ? 'pointer' : 'default',
                            }}
                            // Ensure correct slide link is opened
                        />

                        <Image
                            height={350}
                            width={1400}
                            loading={i !== 0 ? 'lazy' : 'eager'}
                            alt={`banner ${i}`}
                            src={
                                defImg === ''
                                    ? `${APIImage}/${el.image}`
                                    : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                            }
                            className="image-slider"
                            onLoad={() => console.log(`Image ${i} loaded`)}
                        />

                        {(el?.title || el?.content) && (
                            <Fragment>
                                <div className="left-side-contain">
                                    <div className="banner-left">
                                        {el?.title && <h2>{el.title}</h2>}
                                        {el?.content && <h3>{el.content}</h3>}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default VegetablePoster;
